//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import fash from '@/assets/image/fash.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
import { mapState } from 'vuex';
export default {
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      total: 0,
      list: [],
      showCancelOrder: !1,
      showGistics: !1,
      showChangePrice: !1,
      showMessage: !1,
      currentSelectOrderInfo: {},
      logisticsInfo: [], //物流信息
      showOrderSend: !1,
      order_id: '', //订单id
      express_id: '', //物流公司id
      tracking_no: '', //发货订单号
      cancel_reason_id: '', //取消原因id
      cancelInfo: [], //取消信息
      state_remark: '', //取消说明
      revise_freight_fee: '', //修改运费的价格
    };
  },
  components: {
    Paging: Paging,
  },
  computed: {
    ...mapState({
      logisticsList: state => state.order.logisticsList, //物流公司列表
      cancelList: state => state.order.cancelList,
    }),
  },
  watch: {
    showOrderSend(val) {
      if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
    },
    showCancelOrder(val) {
      if (val && !this.cancelList.length) this.$store.dispatch('order/getCancelList', { type: 4 });
    },
  },
  created() {
    let utils = this.$store.state.utils;
    if (utils.is_record) {
      this.content.page = utils.page;
      this.content.rows = utils.rows;
      utils.is_record = !1;
    }
    this.getList();
  },
  methods: {
    confirmSend() {
      if (!this.tracking_no)
        return this.$message({
          message: '请填写快递单号',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.order.setSend, {
          id: this.currentSelectOrderInfo.id,
          need_express: 1,
          express_id: this.express_id,
          tracking_no: this.tracking_no,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message({
              message: '发货成功',
              type: 'success',
            });
            this.getList();
            this.$parent.$parent.getOrderNum();
            this.showOrderSend = !1;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateData(val, status) {
      if (status == 0) {
        this.content.rows = val;
      } else {
        this.content.page = val;
      }
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    orderInfo(id) {
      this.$router.push({
        path: '/goods/orderInfo',
        query: {
          id: id,
          page: this.content.page,
          rows: this.content.rows,
        },
      });
    },
    //确认收货
    confirmReceipt(index) {
      let orderInfo = this.list[index].extend_order_goods;
      for (let i in orderInfo) {
        if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可确认收货！');
      }
      this.$confirm(`是否确认强制收货（谨慎使用！）`, '提示', {
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.order.confirmReceipt, { id: this.list[index].id }).then(res => {
          if (res.code == 0) {
            this.$message({
              message: '订单收货成功',
              type: 'success',
            });
            this.getList();
            this.$parent.$parent.getOrderNum();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //打印
    print(row) {
      let routeData = this.$router.resolve({
        path: '/print',
        query: {
          id: row.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    //取消订单
    cancelOrder(row) {
      this.currentSelectOrderInfo = row;
      this.state_remark = '';
      this.showCancelOrder = !0;
    },
    comfirmCancelOrder() {
      if (!this.cancel_reason_id)
        return this.$message({
          message: '请选择取消原因',
          type: 'warning',
        });
      if (!this.state_remark)
        return this.$message({
          message: '请填写取消说明',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.order.cancel, {
          id: this.currentSelectOrderInfo.id,
          state_remark: this.state_remark,
          cancel_reason_id: this.cancel_reason_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showCancelOrder = !1;
            this.$message({
              message: '订单已取消',
              type: 'success',
            });
            this.getList();
            this.$parent.$parent.getOrderNum();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //查看物流信息
    logisticsQuery(id) {
      this.$axios.post(this.$api.order.logisticsQuery, { id: id }).then(res => {
        if (res.code == 0) {
          this.showGistics = !0;
          res.result.info.company_name = res.result.company_name;
          this.logisticsInfo = res.result.info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //改价
    changePrice(row) {
      this.currentSelectOrderInfo = row;
      if (this.currentSelectOrderInfo.revise_freight_fee > 0) {
        this.revise_freight_fee = this.currentSelectOrderInfo.revise_freight_fee;
      } else {
        this.revise_freight_fee = this.currentSelectOrderInfo.freight_fee;
      }
      this.showChangePrice = !0;
    },
    comfirmChangePrice() {
      let revise_goods = [];
      let extend_order_goods = this.currentSelectOrderInfo.extend_order_goods;
      for (let i in extend_order_goods) {
        if (!extend_order_goods[i].difference_price)
          return this.$message({
            message: '请填写修改价格',
            type: 'warning',
          });
        if (extend_order_goods[i].difference_price <= 0) return this.$message.warning('修改价格不能小于等于0');
        let data = {};
        data.id = extend_order_goods[i].id;
        data.goods_pay_price = Number(extend_order_goods[i].goods_pay_price);
        data.difference_price = extend_order_goods[i].difference_price;
        revise_goods.push(data);
      }
      this.$axios
        .post(this.$api.order.changePrice, {
          revise_goods: revise_goods,
          revise_freight_fee: Number(this.revise_freight_fee),
        })
        .then(res => {
          if (res.code == 0) {
            this.showChangePrice = !1;
            this.getList();
            this.$message({
              message: '修改成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //设置备注信息
    setSystemMessage(row) {
      this.currentSelectOrderInfo = row;
      this.showMessage = !0;
    },
    comfirmSetSystemMessage() {
      this.$axios
        .post(this.$api.order.setSystemMessage, {
          id: this.currentSelectOrderInfo.id,
          system_message: this.currentSelectOrderInfo.system_message,
        })
        .then(res => {
          if (res.code == 0) {
            this.showMessage = !1;
            this.$message({
              message: '备注成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //发货
    orderSend(row) {
      let orderInfo = row.extend_order_goods;
      for (let i in orderInfo) {
        if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可发货！');
      }
      this.currentSelectOrderInfo = row;
      this.tracking_no = '';
      this.showOrderSend = !0;
    },
    getList() {
      let content = this.content;
      let data = {
        page: content.page,
        rows: content.rows,
        create_time: content.create_time,
      };
      if (content.state_type == 'group_state_pay') {
        data.group_state_type = 'group_state_pay';
        data.state_type = 'all';
      } else {
        data.state_type = content.state_type;
      }
      if (content.payment_code != 'whole') {
        data.payment_code = content.payment_code;
      }
      if (content.feedback_state != 'whole') {
        data.feedback_state = content.feedback_state;
      }
      if (content.from != 'whole') {
        data.from = content.from;
      }
      if (content.keywords) {
        data.keywords_type = content.keywords_type;
        data.keywords = content.keywords;
      }
      if (content.room_id) {
        data.room_id = content.room_id;
        data.goods_id = content.goods_id;
      }
      if (this.$route.query.user_ids) data.user_ids = this.$route.query.user_ids;
      this.$axios.post(this.$api.order.list, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].order_status = list[i].state == 0 ? '已关闭' : list[i].state == 10 ? '待支付' : list[i].state == 20 ? '待发货' : list[i].state == 30 ? '待收货' : '已完成';
            list[i].revise_freight_fee = Number(list[i].revise_freight_fee);
            list[i].revise_amount = Number(list[i].revise_amount);
            list[i].extend_order_extend.reciver_infos.combine_detail = list[i].extend_order_extend.reciver_infos.combine_detail.replace(/\s*/g, '');
            if (list[i].from == 1) list[i].from_icon = wx;
            if (list[i].from == 2) list[i].from_icon = ali;
            if (list[i].from == 3) list[i].from_icon = fash;
            if (list[i].from == 4) list[i].from_icon = douyin;
            if (list[i].from == 5) list[i].from_icon = qq;
            if (list[i].from == 6) list[i].from_icon = baidu;
            let extend_order_goods = list[i].extend_order_goods;
            for (let y in extend_order_goods) {
              extend_order_goods[y].difference_price = extend_order_goods[y].goods_pay_price;
            }
          }
          this.list = list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
